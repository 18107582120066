import React from "react"
// import { Link } from "gatsby"
import styled from '@emotion/styled'

import Layout from "../components/layout"
import HeroVideo from "../images/herovideo.mp4"
import HeroText from '../images/herotext.inline.svg'
import Dates from "../components/dates"
import WhatsFresh from "../components/fresh"
import FeedFam from "../components/feedfam"
import Connect from "../components/connect"
import Sponsors from "../components/sponsors"

const Hero = styled.div`
  display: grid;
  grid-template-rows: 50vh;
  overflow: hidden;

  @media (min-width: 575px) {
    grid-template-rows: 65vh;
  }

  @media (min-width: 1024px) {
    /* grid-template-rows: 65vh; */
  }
`
const HeroVid = styled.video`
  grid-area: 1 / 1 / -1 / -1;
  min-width: 100%;
  height: 50vh;
  object-fit: cover;
  z-index: -1;

  @media (min-width: 575px) {
    height: 65vh;
  }

  @media (min-width: 1024px) {
    /* height: 65vh; */
  }
`

const HeroOverlay = styled.div`
  grid-area: 1 / 1 / -1 / -1;

  display: grid;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.35);
`

const HeroContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: left;
`

const SvgWrapper = styled.div`
  width: 100vw;
  height: auto;
  padding: 0 2em;

  @media (min-width: 575px) {
    width: 80vw;
    margin-left: 5vw;
    margin-right: 15vw;
  }

  @media (min-width: 1024px) {
    width: 60vw;
    margin-left: 5vw;
    margin-right: 35vw;
  }


  @media (min-width: 1600px) {
    width: 60vw;
    margin-left: 5vw;
    margin-right: 30vw;

  }

`


const IndexPage = () => (
  <Layout>
    <Hero>
      <HeroVid loop autoPlay muted>
        <source src={HeroVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </HeroVid>
      <HeroOverlay>
        <HeroContent>
          <SvgWrapper>
            <HeroText />
          </SvgWrapper>
        </HeroContent>
      </HeroOverlay>
    </Hero>
    <Dates />
    <WhatsFresh />
    <FeedFam />
    <Connect />
    <Sponsors />
  </Layout>
)

export default IndexPage
