// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import LeftImage from "./index/leftimage"
import CenterImage from "./index/centerimage"
import RightImage from "./index/rightimage"
import Tear1 from "../images/tear1.png"
import Tear2 from "../images/tear2.png"
import Tear3 from "../images/tear3.png"
import RoosterRed from "../images/icon-rooster-red.inline.svg"
import RoosterOrange from "../images/icon-rooster-orange.inline.svg"
import RoosterGreen from "../images/icon-rooster-green.inline.svg"

const WhatsFresh = styled.div`
  /* padding: 6em 2em; */
  background: #f9f9f9;
  border-top: 2px solid rgba(0,0,0,.9);
  border-bottom: 4px solid rgba(0,0,0,.9);
`

const WFWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4em 1.5em;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 8em 2em;
  }

`

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  background: #e8e8e8;
  flex-direction: column;
  box-shadow:
    0 2.2px 5.8px rgba(0, 0, 0, 0.02),
    0 5.3px 14px rgba(0, 0, 0, 0.028),
    0 10px 26.3px rgba(0, 0, 0, 0.035),
    0 17.9px 46.9px rgba(0, 0, 0, 0.042),
    0 33.4px 87.7px rgba(0, 0, 0, 0.05),
    0 80px 210px rgba(0, 0, 0, 0.07);
  text-align: center;
  border-top: 3px solid #CD6A5E;
  position: relative;

  @media (max-width: 1023px) {
    margin-bottom: 6em;
  }

  > svg {
    position: absolute;
    z-index: 4;
    height: auto;
    width: 60px;
    top: -30px;
    right: calc(50% - 30px);
  }

  p {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    margin: 0 1.25em 3em;
    color: #555555;

    @media (min-width: 1024px) {
      margin: 0 3em 4em;
    }
  }

`
const Center = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: #e8e8e8;
  margin-top: -2em;
  margin-bottom: -2em;
  box-shadow:
    0 2.2px 5.8px rgba(0, 0, 0, 0.02),
    0 5.3px 14px rgba(0, 0, 0, 0.028),
    0 10px 26.3px rgba(0, 0, 0, 0.035),
    0 17.9px 46.9px rgba(0, 0, 0, 0.042),
    0 33.4px 87.7px rgba(0, 0, 0, 0.05),
    0 80px 210px rgba(0, 0, 0, 0.07)
  ;
  z-index: 3;
  text-align: center;
  position: relative;
  border-top: 3px solid #A32332;

  @media (max-width: 1023px) {
    margin-bottom: 4em;
  }

  > svg {
    position: absolute;
    z-index: 4;
    height: auto;
    width: 60px;
    top: -30px;
    right: calc(50% - 30px);
  }

    p {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      margin: 0 1.25em 3em;
      color: #555555;

      b {
        font-weight: 500;
      }
      @media (min-width: 1024px) {
        margin: 0 3em 4em;
      }
    }
)

`
const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  background: #e8e8e8;
  flex-direction: column;
  box-shadow:
    0 2.2px 5.8px rgba(0, 0, 0, 0.02),
    0 5.3px 14px rgba(0, 0, 0, 0.028),
    0 10px 26.3px rgba(0, 0, 0, 0.035),
    0 17.9px 46.9px rgba(0, 0, 0, 0.042),
    0 33.4px 87.7px rgba(0, 0, 0, 0.05),
    0 80px 210px rgba(0, 0, 0, 0.07);
  text-align: center;
  border-top: 3px solid #799A31;
  position: relative;

  > svg {
    position: absolute;
    z-index: 4;
    height: auto;
    width: 60px;
    top: -30px;
    right: calc(50% - 30px);
  }

    p {
      font-size: 16px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      margin: 0 1.25em 3em;
      color: #555555;
      @media (min-width: 1024px) {
        margin: 0 3em 4em;
      }
    }
`

const ImageWrapper = styled.div`
  height: 300px;
  width: 100%;
  position: relative;

  svg {
    position: absolute;
    bottom: 0;
    fill: #e8e8e8;
    width: 100%;
  }

  img {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

`

const Title = styled.h2`
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 22px;
  line-height: 114%;
  color: #27251f;
  margin: 0em 2em 1em;
  -webkit-font-smoothing: antialiased;

  @media (min-width: 1024px) {
    font-size: 26px;
  }
`


const FreshWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "wood.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.placeholderImage.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7))`,
  ].reverse()


  return (

  <WhatsFresh>
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      style={{width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "center"}}
      >
      <WFWrapper>
        <Left>
          <RoosterOrange />
          <ImageWrapper>
            <LeftImage />
            <img src={Tear2} alt="" />
          </ImageWrapper>
          <Title>Bountiful Beets</Title>
          <p>Rocky River Farms will be at all three locations this month offering their beets from their massive harvest! Beets are packed with essential vitamins, minerals and plant compounds, some of which have medicinal properties. What's more, they are delicious and easy to add to your diet.</p>
        </Left>
        <Center>
          <RoosterRed />
          <ImageWrapper>
            <CenterImage />
            <img src={Tear1} alt="" />
          </ImageWrapper>
          <Title>End of Season for<br/>Meadow Lake</Title>
          <p>With Fall now in full swing the Meadow Lake market will be closing for the season. <b>The last market day for Meadow Lake will be Sunday, November 8th, 2020.</b><br/><br/>The Wednesday market at Memorial Park and Saturday market at Fairview Elementary operate year round to provide our community and your family with fresh produce.</p>
        </Center>
        <Right>
          <RoosterGreen />
          <ImageWrapper>
            <RightImage />
            <img src={Tear3} alt="" />
          </ImageWrapper>
          <Title>Plenty of Peaches</Title>
          <p>All three market locations now have vendors offering a wide variety of peaches including Red Haven, Biscoe, and Contender. Enjoy them at their peak of sweetness right before fall. Now would be the perfect time to stock up for canning so you can enjoy this sweet treat all winter long!</p>
        </Right>
      </WFWrapper>
    </BackgroundImage>
  </WhatsFresh>
  )
}

export default FreshWrapper
