import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const ImageWrapper = styled.div`
height: 150px;
width: 100%;

@media (min-width: 1024px) {
  height: 250px;
}

@media (min-width: 1200px) {
  height: 300px;
}
`

const FreshWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "i3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.placeholderImage.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))`,
  ].reverse()


  return (

  <ImageWrapper>
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      style={{width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "center"}}
      >
    </BackgroundImage>
  </ImageWrapper>
  )
}

export default FreshWrapper
