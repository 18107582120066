// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import HeaderLogo from "../images/connect.inline.svg"
import InstaPhoto1 from "./index/instaphoto1"
import InstaPhoto2 from "./index/instaphoto2"
import InstaPhoto3 from "./index/instaphoto3"
import InstaPhoto4 from "./index/instaphoto4"
import InstaPhoto5 from "./index/instaphoto5"
import InstaPhoto6 from "./index/instaphoto6"
import InstaPhoto7 from "./index/instaphoto7"
import InstaPhoto8 from "./index/instaphoto8"
import InstaPhoto9 from "./index/instaphoto9"

const Wrapper = styled.div`
  width: 100%;
`

const ConnectMenu = styled.div`
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 0;
  justify-content: center;
  text-align: center;
  flex-direction: column;


  svg {
    width: 80vw;
  }

  @media (min-width: 575px) {
    padding: 8em 1em;
  }

  @media (min-width: 1024px) {
    svg {
      width: 45vw;
    }
  }


`

const Insta = styled.div`
  width: 100%;
  margin-top: 3em;
  padding: 0 1em;

  @media (min-width: 1024px) {
    width: 90%;
  }

`

const InstaPhoto = styled.div`
  width: calc(33.3333% - 1em);
  height: auto;
  overflow: hidden;
  margin: .5em;
  float: left;

  @media (min-width: 1024px) {
    width: calc(33.3333% - 2em);
    margin: 1em;
  }
`

const ConnectWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "cbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <BackgroundImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ borderTop: "3px solid rgba(0,0,0,.8)", backgroundColor: "#f2f2f2", backgroundSize: "auto", backgroundRepeat: "repeat"}}
        >
          <ConnectMenu>
            <HeaderLogo />
            <Insta>
              <InstaPhoto>
                <InstaPhoto1 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto2 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto3 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto4 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto5 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto6 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto7 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto8 />
              </InstaPhoto>
              <InstaPhoto>
                <InstaPhoto9 />
              </InstaPhoto>
            </Insta>
          </ConnectMenu>
      </BackgroundImage>
    </Wrapper>
  )
}

export default ConnectWrapper
