// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

const Wrapper = styled.div`
  width: 100%;
`

const DatesMenu = styled.div`
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1.5em;
  justify-content: space-between;
  text-align: center;
  color: #fdf5bf;
  flex-direction: column;

  @media (min-width: 840px) {
    flex-direction: row;
  }
`

const Saturday = styled.div`
  flex: 1;
  padding: 1em 0;
  width: 100%;

  @media (min-width: 840px) {
    padding: 0;
  }
`
const Sunday = styled.div`
  flex: 1;
  border-top: 2px solid rgba(0,0,0,0.35);
  padding-top: 1em;
  width: 100%;

  @media (min-width: 840px) {
    padding-top: 0;
    border-top: none;
    border-left: 2px solid rgba(0,0,0,0.35);
  }
`
const Wednesday = styled.div`
  flex: 1;
  border-bottom: 2px solid rgba(0,0,0,0.35);
  padding-bottom: 1em;
  width: 100%;

  @media (min-width: 840px) {
    padding-bottom: 0;
    border-bottom: none;
    border-right: 2px solid rgba(0,0,0,0.35);
  }

`
const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: .5em;
`

const Location = styled.div`
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "Roboto Slab", sans-serif;
  color: #fcfcfc;
`
const Time = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
`


const DatesWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rustbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <BackgroundImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ borderTop: "2px solid rgba(0,0,0,.5)", backgroundColor: "#034C3C", width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "left bottom"}}
        >
          <DatesMenu>
            <Wednesday>
              <Title>Wednesday</Title>
              <Location>Memorial Park</Location>
              <Time>3pm&mdash;6pm</Time>
            </Wednesday>
            <Saturday>
              <Title>Saturday</Title>
              <Location>Fairview Elementary</Location>
              <Time>12pm&mdash;4pm</Time>
            </Saturday>
            <Sunday>
              <Title>Sunday</Title>
              <Location>Meadow Lake</Location>
              <Time>7am&mdash;1pm</Time>
            </Sunday>
          </DatesMenu>
      </BackgroundImage>
    </Wrapper>
  )
}

export default DatesWrapper
