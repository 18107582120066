// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'
import FeedFamImage from './feedfamimage'

const WhatsFresh = styled.div`
  /* padding: 6em 2em; */
  background: #f9f9f9;
`

const WFWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 4em 1.5em;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 8em 2em;
  }

`

const BoxWrap = styled.div`
  background: #f2f2f2;
  width: 100%;
  /* margin-left: 3em; */
  padding: 0 0 4em;
  display: flex;
  border-radius: 1px;
  flex-direction: column;

  @media (min-width: 1024px) {
    padding: 4em 0;
    flex-direction: row;
    margin-left: 3em;
  }

  @media (min-width: 1200px) {
    margin-left: 6em;
  }
`

const FeedFamImageWrap = styled.div`
  height: auto;
  /* padding: 4em 0; */
  /* margin-left: -3em; */

  @media (min-width: 1024px) {
    width: 50%;
    margin-left: -3em;

    .gatsby-image-wrapper {
       box-shadow:
         0 0px 2.2px rgba(0, 0, 0, 0.02),
         0 0px 5.3px rgba(0, 0, 0, 0.028),
         0 0px 10px rgba(0, 0, 0, 0.035),
         0 0px 17.9px rgba(0, 0, 0, 0.042),
         0 0px 33.4px rgba(0, 0, 0, 0.05),
         0 0px 80px rgba(0, 0, 0, 0.07);
     }
  }

  @media (min-width: 1200px) {
    margin-left: -6em;
  }

`

const FeedFamContent = styled.div`
  /* padding-left: 2em; */
  width: 100%;
  padding: 2em 1.5em 0;

  @media (min-width: 800px) {
    width: 80%;
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding-left: 3em;
    padding-top: 0;
  }

  @media (min-width: 1200px) {
    padding-left: 4em;
  }

  @media (min-width: 1400px) {
    padding-left: 6em;
  }

  h3 {
    font-family: "Roboto Slab", serif;
    font-size: 26px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin-bottom: .5em;
    color: #27251f;

    @media (min-width: 1024px) {
      font-size: 36px;
    }
  }

  h4 {
    font-family: "Roboto Slab", serif;
    font-size: 18px;
    font-weight: 600;
    -webkit-font-smoothing: antialiased;
    margin-bottom: .5em;
    color: #27251f;
    margin: 2em 0 .5em;
    line-height: 110%;
  }

  p {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    color: #555555;
  }
`

const FeedFam = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tin.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const backgroundFluidImageStack = [
    data.placeholderImage.childImageSharp.fluid,
    `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))`,
  ].reverse()


  return (

  <WhatsFresh>
    <BackgroundImage
      fluid={backgroundFluidImageStack}
      style={{width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "center"}}
      >
      <WFWrapper>
        <BoxWrap>
          <FeedFamImageWrap>
            <FeedFamImage />
          </FeedFamImageWrap>
          <FeedFamContent>
            <h3>Farmers Markets Matter</h3>
            <p>Farmers markets are more than just a place to find fresh, local food. Across the country, evidence shows farmers markets are significantly improving the health and well-beging for their community, economy, and environment. As support for farmers markets continue to grow, so will the ways their communities prosper.</p>
            <h4>Stimulate Local Encomonies</h4>
            <p>Growers selling locally create 13 full time farm operator jobs per $1 million in revenue earned. Those that do not sell locally create 3.</p>
            <h4>Preserve America’s Rural Livelihoods and Farmland</h4>
            <p>Farmers markets provide one of the only low-barrier entry points for beginning farmers, allowing them to start small, test the market, and grow their businesses.</p>
            <h4>Increase Access to Fresh, Nutritious Food</h4>
            <p>Several  studies have found lower prices for conventional and organic produce at farmers markets than at supermarkets. Due to this and other factors, 52% more SNAP households shop at farmers markets and from direct marketing farmers today than in 2011.</p>
          </FeedFamContent>
        </BoxWrap>
      </WFWrapper>
    </BackgroundImage>
  </WhatsFresh>
  )
}

export default FeedFam
