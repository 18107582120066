// import { Link } from "gatsby"
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

import So from '../images/so.inline.svg'
import Wf from '../images/wf.inline.svg'
import Cdi from '../images/cdi.inline.svg'
import Lh from '../images/lh.inline.svg'
import Cw from '../images/cw.inline.svg'
import Tlf from '../images/tlf.inline.svg'


const Wrapper = styled.div`
  width: 100%;
`

const SponsorsMenu = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  padding: 4em 2em 2em;
  text-align: center;
  color: #fdf5bf;

  @media (min-width: 840px) {
    flex-direction: column;
  }
`

const SponsorsText = styled.div`
  font-family: "Roboto Slab", sans-serif;
  font-size: 22px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
`
const SponsorsLogos = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 1200px) {
    width: 90%;
  }

  @media (min-width: 1400px) {
    width: 80%;
  }

  > div {
    width: 50%;
    height: auto;
    padding: 2em;

    @media (min-width: 620px) {
      width: 33.333%;
    }

    @media (min-width: 1024px) {
      width: 16.666%;
    }

    svg {
      max-width: 100%;
    }
  }
}
`

const SponsorsWrapper = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rustbg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <BackgroundImage
        fluid={data.placeholderImage.childImageSharp.fluid}
        style={{ borderTop: "4px solid rgba(0,0,0,.5)", borderBottom: "4px solid rgba(0,0,0,.5)", backgroundColor: "#034C3C", width: "auto", height: "100%", backgroundSize: "cover", backgroundPosition: "left bottom"}}
        >
          <SponsorsMenu>
            <SponsorsText>
              The 2020 Fairview Farmers Market season is sponsored by
            </SponsorsText>
            <SponsorsLogos>
              <div><Wf /></div>
              <div><So /></div>
              <div><Cdi /></div>
              <div><Lh /></div>
              <div><Cw /></div>
              <div><Tlf /></div>
            </SponsorsLogos>
          </SponsorsMenu>
      </BackgroundImage>
    </Wrapper>
  )
}

export default SponsorsWrapper
